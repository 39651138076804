<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>
        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
        <h3>
          <b>Rooms Management</b>
          <!-- <span class="pull-right">#5669626</span> -->
        </h3>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <Table
              :items="items"
              :filter="filters"
              :header="headers"
              :onAction="get"
              :onDetail="doDetail"
              :onEdit="doEdit"
              @onDelete="doDelete"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from "@/components/table/table";
import { RotateSquare5 } from "vue-loading-spinner";

export default {
  components: {
    RotateSquare5,
    Table
  },
  data() {
    return {
      headers: {
        name: {
          label: "Nama Ruangan",
          sortable: true
        },
        data_opd: {
          label: "OPD",
          sortable: false
        },
        phone: {
          label: "Telphone",
          sortable: false
        },
        address: {
          label: "Alamat",
          sortable: true
        },
        capacity: {
          label: "Kapasitas",
          sortable: true
        },
        status: {
          label: "Status Ruangan",
          sortable: true
        }
      }
    };
  },
  computed: {
    items() {
      return this.$store.state.rooms.items
        ? this.$store.state.rooms.items.items
        : [];
    },
    filters() {
      return this.$store.state.rooms.items
        ? this.$store.state.rooms.items.filters
        : {};
    },
    state() {
      return this.$store.state.rooms;
    }
  },
  mounted() {
    const state = {
      loaded: false,
      items: []
    };
    this.$store.commit("rooms/STATE", state);
    this.get(state);
  },
  methods: {
    get(val) {
      this.$store.dispatch("rooms/getRooms", val);
    },
    doDetail(val) {
      this.$store.dispatch("rooms/onDetail", val);
    },
    doEdit(val) {
      this.$store.dispatch("rooms/onEdit", val);
    },
    doDelete(val) {
      this.$store.dispatch("rooms/submitDelete", val);
    }
  }
};
</script>
